export function getRoute() {
  return {
    main: {
      charity: () => '/*',
    },
    charity: {
      messages: {
        cardsRoute: () => '/',
        createRoute: () => '/form',
      },
    },
  };
}
