import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FooterLink } from '../../../types.ts';

export function useHistoryAndCatsController() {
  const { t } = useTranslation();

  const historyAndCatsText = t('footer.historyAndCats');

  const links: FooterLink[] = useMemo(() => {
    return [
      { id: 1, icon: 'vk', link: 'https://vk.com/historyandcats' },
      { id: 2, icon: 'telegram', link: 'https://t.me/historyandcats' },
      { id: 3, icon: 'youtube', link: 'https://youtube.com/@historyandcats' },
      { id: 4, icon: 'web', link: 'https://historyandcats.ru' },
    ] as FooterLink[];
  }, []);

  return { historyAndCatsText, links };
}
