import { GridContainer } from '../../../../../../../ui/index.ts';
import { MainImage1, MainImage2 } from './styles.ts';
import mainImage1 from './images/main-image-1.png';
import mainImage2 from './images/main-image-2.png';

export function Photos() {
  return (
    <GridContainer gridTemplateRows="repeat(2, 1fr)" alignItems="end">
      <GridContainer justifyContent="end">
        <MainImage1 src={mainImage1} />
      </GridContainer>
      <GridContainer>
        <MainImage2 src={mainImage2} />
      </GridContainer>
    </GridContainer>
  );
}
