import { PropsWithChildren } from 'react';
import { FlexContainer, GridContainer } from '../../containers/index.ts';
import { Icon } from '../Icon/index.tsx';
import { IconLoader } from '../../shared/index.ts';
import { Text } from '../Text/index.ts';
import { Container } from './styles.ts';
import { FormCardViewProps } from './types.ts';

export * from './types.ts';

export function FormCard({
  label,
  icon,
  maxWidth,
  children,
  isLoading,
  className,
}: PropsWithChildren<FormCardViewProps>) {
  return (
    <Container
      className={className}
      maxWidth={maxWidth}
      padding="XS"
      border="light-beige"
      background="main-background"
      loaderBackground="light-beige"
      loaderForeground="main-background"
    >
      <GridContainer gap="32" gridTemplateRows="max-content" alignItems="center">
        <GridContainer gap="32" gridTemplateRows="max-content" justifyContent="center">
          <Text
            text={label}
            size={32}
            weight="Giant"
            color="black-primary"
            align="center"
            uppercase
            family="secondary"
          />
          <FlexContainer justifyContent="center">
            {isLoading ? (
              <IconLoader size={40} />
            ) : (
              <Icon glyph={icon} size={40} color="pink" fallback={<IconLoader size={40} />} />
            )}
          </FlexContainer>
        </GridContainer>
        {children}
      </GridContainer>
    </Container>
  );
}
