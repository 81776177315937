import { ContentLoader } from '../ContentLoader/index.tsx';
import { IconLoaderViewProps } from './types.ts';

export function IconLoader({ size = 16, height, width }: IconLoaderViewProps) {
  return (
    <ContentLoader width={`${width || size}px`} height={`${height || size}px`}>
      <rect x="0" y="0" rx="3" ry="3" width={`${width || size}px`} height={`${height || size}px`} />
    </ContentLoader>
  );
}
