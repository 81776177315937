export const charity = {
  charity: {
    main: {
      title: 'Подари свои баллы ЕГЭ',
      button: 'ПОДАРИТЬ!',
    },
    steps: {
      title: '#подарибаллы',
      titleBlock: 'инициатор акции: онлайн-школа "история и котики" (подготовка к егэ и огэ)',
      step1: 'Как подарить свои баллы?',
      step2: 'Посчитайте сумму своих баллов егэ',
      step3: 'Найдите в своем городе приют',
      step4: 'Отправьте пожертвование, равное сумме ваших баллов!',
      step5: 'Или можно купить корм/игрушки и отнести лично!',
      step6: 'Расскажите об этом на нашем сайте',
    },
    whatsNext: {
      title: 'Что еще можно сделать?',
      step1: 'Пригласить поучаствовать в акции друзей',
      step2: 'Рассказать об акции в соцсетях с тегом #подарибаллы',
      step3: 'Можно пожертвовать больше, чем сумма ваших егэ! или делать регулярные пожертвования',
    },
    about: {
      title: 'Про акцию >',
      text1:
        'Привет! Меня зовут Маша, я руководитель школы "История и котики": мы готовим к ЕГЭ и ОГЭ по истории. В своей школе я каждый месяц отправляла % с ежемесячных продлений курса учениками на благотворительность в различные фонды и приюты.',
      text2:
        'В 2023 у меня появилась идея: а не превратить ли это в крутую всероссийскую акцию? Так появилась идея #подарибаллы. В рамках акции выпускники смогут помочь приютам и фондам, пожертвовав им сумму, равную их баллам ЕГЭ.',
      text3:
        'Это небольшая сумма, но если сделать акцию массовой – можно много кому помочь! И сформировать у молодых ребят хорошую привычку. Давайте сделаем это вместе! <3',
      tag: '#подарибаллы',
    },
    form: {
      label: 'Подарили свои баллы? расскажите нам!',
      fields: {
        name: 'Ваше имя',
        score: 'Количество баллов',
        description: 'Пожелания/Любой текст',
        image: 'Прикрепить фото',
      },
    },
  },
};
