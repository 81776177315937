import { RefAttributes } from 'react';
import loadable from '@loadable/component';
import { InputLoader } from '../../shared/index.ts';
import { LoadableFunctionalComponent } from '../../utils/index.ts';
import { TextInputViewProps } from './component/types.ts';

export * from './component/types.ts';

export const TextInput = loadable(() => import('./component/index.tsx'), {
  resolveComponent: (components) => components.TextInput,
  fallback: <InputLoader />,
}) as LoadableFunctionalComponent<TextInputViewProps & RefAttributes<HTMLInputElement>>;
