import { PropsWithChildren } from 'react';
import { DefaultButton, CircleButton, SmallButton } from './components/index.ts';
import { ButtonViewProps } from '../../types.ts';

export function ButtonByType(props: PropsWithChildren<ButtonViewProps>) {
  const { type } = props;
  switch (type) {
    case 'circle':
      return <CircleButton {...props} />;
    case 'small':
      return <SmallButton {...props} />;
    default:
      return <DefaultButton {...props} />;
  }
}
