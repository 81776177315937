import { THEME_COLORS } from './types.ts';

export const COLORS: THEME_COLORS = {
  'gray-primary': '#969696',
  'gray-senary': '#a8a5ab',

  'white-primary': '#ffffff',

  'black-primary': '#000000',

  'main-deep-1': '#F6F8FF',
  'main-deep-2': '#DCE3F5',
  'main-deep-3': '#5A79CB',
  'main-deep-4': '#1F3B8E',
  'main-deep-5': '#15265A',

  'error-deep-1': '#FFF6F6',
  'error-deep-2': '#F5DCDC',
  'error-deep-3': '#B80929',
  'error-deep-4': '#6E0518',

  'success-deep-1': '#F6FFF6',
  'success-deep-2': '#E4F6ED',
  'success-deep-3': '#62D3A4',
  'success-deep-4': '#0F7C62',
  'success-deep-5': '#084335',

  'warning-deep-1': '#FFFAEA',
  'warning-deep-2': '#FFEFC5',
  'warning-deep-3': '#EACD83',
  'warning-deep-4': '#C65C10',

  'gray-deep-1': '#E9E9EA',
  'gray-deep-2': '#C3C4C9',
  'gray-deep-3': '#8A8C91',
  'gray-deep-4': '#4C4E4F',

  'nox-deep-5': '#6A399E',
  'nox-deep-2': '#E5CDFF',

  // Form theme colors

  'primary-text-color': '#373D3B',
  'note-text-color': 'rgba(25, 29, 38, 0.5)',
  'default-text-color': 'rgb(104, 104, 104)',
  'active-text-color': 'rgb(104, 104, 104)',
  'powered-text-color': '',
  'error-text-color': '#871d1d',
  'warning-text-color': '#8d6213',
  'placeholder-text-color': 'rgba(106, 57, 158, 0.5)',
  'success-text-color': '#025a2b',
  'submit-button-text-color': '',
  'secondary-button-border-color': 'rgba(25, 29, 38, 0.5)',
  'secondary-button-background-color': '#fff',
  'media-primary-text-color': 'rgba(106, 57, 158, 1)',
  'default-state-color': '#BCBEC0',
  'media-secondary-text-color': 'rgba(106, 57, 158, 0.5)',

  'input-border': 'rgba(106, 57, 158, 0.5)',
  'input-outline': 'rgba(106, 57, 158, 0.5)',
  'input-outline-secondary': 'rgba(106, 57, 158, 0.5)',

  'input-focus-border': 'rgba(106, 57, 158, 1)',
  'input-focus-background': 'rgba(106, 57, 158, 1)',
  'input-focus-background-reverse': 'rgba(106, 57, 158, 1)',

  'scrollbar-background': 'rgba(106, 57, 158, 0.3)',

  'disabled-input-background': 'rgba(106, 57, 158, 0.1)',

  'main-background': '#FAEFE6',

  pink: '#D46BCA',
  'deep-purple': '#553566',
  'light-purple-1': '#E8CACE',
  'light-purple-2': '#CAAACD',

  orange: 'rgb(253, 136, 61)',
  'deep-orange': '#EF601E',
  red: '#C5460F',

  blue: '#D6E8F7',

  'light-blue': '#D6E8F7',

  mustard: '#CB962E',

  beige: '#F1DFB6',
  'light-beige': '#FDF3DD',
  'light-beige-2': '#FFE7D3',
};
