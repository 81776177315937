import { GridContainer, Text, Icon } from '../../../../../../../ui/index.ts';
import { useNextStepsController } from './controller/index.ts';
import { StepImage } from './styles.ts';
import { NextStep } from './types.ts';

function renderStep({ id, text, image }: NextStep) {
  return (
    <GridContainer key={id} gap="20">
      <GridContainer justifyContent="center">
        <StepImage src={image} />
      </GridContainer>
      <GridContainer gridTemplateColumns="max-content 1fr">
        <Icon glyph="star" color="orange" size={25} />
        <Text
          text={text}
          size={18}
          align="center"
          uppercase
          family="secondary"
          color="deep-purple"
        />
      </GridContainer>
    </GridContainer>
  );
}

export function NextSteps() {
  const { steps } = useNextStepsController();

  return (
    <GridContainer gap="18" gridTemplateColumns="repeat(auto-fit, minmax(250px, 1fr))">
      {steps.map(renderStep)}
    </GridContainer>
  );
}
