import styled from '@emotion/styled';
import { GridContainer, Text, getSpace } from '../../../../../ui/index.ts';

export const Container = styled(GridContainer)`
  padding: ${getSpace('50')} ${getSpace('28')} ${getSpace('20')} ${getSpace('28')};
`;

export const Title = styled(Text)`
  width: 80%;

  @media only screen and (max-width: 600px) {
    width: 100%;
    font-size: 40px;
  }
`;
