import styled from '@emotion/styled';
import { getSpace } from '../../../utils/index.ts';
import { Text } from '../../Text/index.ts';

export const ErrorContainer = styled.div`
  display: flex;
  align-items: center;
  padding-left: ${getSpace('14')};

  & > div:first-of-type {
    margin-right: 6px;
  }
`;

export const ErrorRow = styled(Text)`
  display: block;
`;

export const NoteContainer = styled.div`
  display: flex;
  gap: ${getSpace('6')};
  padding-left: ${getSpace('14')};

  & > div:first-of-type {
    flex-shrink: 0;
  }
`;
