import { ContentLoader } from '../ContentLoader/index.tsx';

export function MediaLoader() {
  return (
    <ContentLoader width="100%" height="303.59">
      <rect x="14" y="0" rx="3" ry="3" width="100%" height="15.59" />
      <rect x="0" y="21.59" rx="3" ry="3" width="100%" height="282" />
    </ContentLoader>
  );
}
