export const common = {
  common: {
    form: {
      actions: {
        send: 'Отправить',
        cancel: 'Отмена',
      },
    },
  },
};
