import { Container, Title } from './styles.ts';
import { NextSteps } from './components/index.ts';
import { useWhatsNextCardController } from './controller/index.ts';

export function WhatsNextCard() {
  const { whatsNextTitle } = useWhatsNextCardController();

  return (
    <Container gap="18">
      <Title
        text={whatsNextTitle}
        size={60}
        align="center"
        uppercase
        family="secondary"
        color="deep-purple"
      />
      <NextSteps />
    </Container>
  );
}
