import { CharityMessage } from '../../../../../../packages/api/messages/types.ts';
import { Text, GridContainer } from '../../../../../ui/index.ts';
import { Fallback, ActionButton } from './components/index.ts';
import { useMessagesCardController } from './controller/index.ts';
import {
  ScrollContainer,
  EmptyContainer,
  MessagesContainer,
  MessageContainer,
  MessageImage,
  NameAndCount,
  Description,
  MessageImgContainer,
} from './styles.ts';
import { MessagesCardViewProps } from './types.ts';

export function RenderMessage({
  id,
  user_score,
  user_first_name,
  user_description,
  user_photo_id,
}: CharityMessage) {
  return (
    <MessageContainer key={id} gridTemplateRows="repeat(3, max-content)" gap="18">
      <MessageImgContainer justifyContent="center">
        <MessageImage src={user_photo_id} />
      </MessageImgContainer>
      <GridContainer gridTemplateColumns="100px 100px" justifyContent="space-between">
        <NameAndCount text={user_first_name} color="pink" family="secondary" weight="Bold" />
        <NameAndCount
          text={`Баллы: ${user_score.toString()}`}
          color="pink"
          family="secondary"
          weight="Bold"
        />
      </GridContainer>
      <Description text={user_description} color="pink" family="secondary" weight="Bold" />
    </MessageContainer>
  );
}

export function MessagesCard({ messagesRef }: MessagesCardViewProps) {
  const { infinityRef, isEmpty, messages, hasLoadMore } = useMessagesCardController();

  return (
    <MessagesContainer gridTemplateRows="repeat(2, max-content)" gap="40" ref={messagesRef}>
      <GridContainer gap="18">
        <Text
          text="Они подарили свои баллы!"
          color="black-primary"
          family="secondary"
          weight="Bold"
          size={22}
          align="center"
        />
        <GridContainer justifyContent="center">
          <ActionButton />
        </GridContainer>
      </GridContainer>
      <ScrollContainer
        gap="24"
        gridTemplateColumns="repeat(auto-fit, minmax(300px, 1fr))"
        justifyContent="space-evenly"
      >
        {messages?.map(RenderMessage)}
        <Fallback infinityRef={infinityRef} hasLoadMore={hasLoadMore} />
      </ScrollContainer>
      {isEmpty && (
        <EmptyContainer
          isEmpty={isEmpty}
          gridTemplateColumns="1fr"
          justifyContent="center"
          gap="10"
        >
          <Text text="Пусто(" align="center" color="main-deep-4" />
        </EmptyContainer>
      )}
    </MessagesContainer>
  );
}
