import styled from '@emotion/styled';
import { Image, GridContainer, getColor, getSpace } from '../../../../../../../../../ui/index.ts';

export const Container = styled(GridContainer)`
  margin-bottom: 20px;
`;

export const CatImageContainer = styled.div`
  background: ${getColor('light-purple-2')};
  padding: ${getSpace('8')} ${getSpace('8')} ${getSpace('8')} ${getSpace('8')};
  overflow: hidden;
  max-width: 200px;
  border-radius: 20px;
`;

export const DogImageContainer = styled.div`
  background: ${getColor('light-purple-2')};
  padding: ${getSpace('8')} ${getSpace('8')} ${getSpace('8')} ${getSpace('8')};
  overflow: hidden;
  max-width: 200px;
  border-radius: 20px;
`;

export const CatImage = styled(Image)`
  height: 120px;
`;

export const DogImage = styled(Image)`
  height: 120px;
`;
