import { charity } from './charity/index.ts';
import { footer } from './footer/index.ts';
import { common } from './common/index.ts';

export default {
  translation: {
    ...charity,
    ...footer,
    ...common,
  },
};
