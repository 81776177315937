import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { getRoute } from '../../../../../../../../../packages/context/index.ts';

export function useActionButtonController() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const buttonText = t('charity.main.button');

  const onButtonClick = useCallback(() => {
    navigate({ pathname: getRoute().charity.messages.createRoute() });
  }, [navigate]);

  return { buttonText, onButtonClick };
}
