import styled from '@emotion/styled';
import { GridContainer, Text, Image, getSpace, getColor } from '../../../../../ui/index.ts';

export const ScrollContainer = styled(GridContainer)`
  overflow-anchor: none;
  overflow: auto;
`;

export const EmptyContainer = styled(GridContainer)`
  max-height: max-content;
  padding: ${getSpace('32')};
`;

export const MessagesContainer = styled(GridContainer)`
  overflow: auto;
  padding: ${getSpace('12')};
`;

export const MessageContainer = styled(GridContainer)`
  min-height: 350px;
  background: ${getColor('pink')};
  padding: ${getSpace('12')};
  border-radius: 20px;
`;

export const MessageImgContainer = styled(GridContainer)`
  overflow: hidden;
  border-radius: 20px;
`;

export const MessageImage = styled(Image)`
  height: 200px;
  border-radius: 20px;
`;

export const NameAndCount = styled(Text)`
  padding: ${getSpace('4')};
  background: ${getColor('white-primary')};
  border-radius: 5px;
  overflow: hidden;
`;

export const Description = styled(Text)`
  padding: ${getSpace('4')};
  background: ${getColor('white-primary')};
  border-radius: 5px;
  height: 80px;
  overflow: hidden;
`;
