import { css } from '@emotion/react';
import { PREFIX } from '../utils/index.ts';
import { FONTS } from './constants.ts';
import Lazydog from './files/Lazydog.ttf';

export const APP_FONTS = css(
  Object.entries(FONTS).reduce(
    (acc, [key, value]) => `${acc}
      ${PREFIX.FONT}${key}: ${value || 'none'};`,
    ''
  )
);

export const APP_LAZY_DOG_FONT = css(
  `
    @font-face {
      font-family: 'Lazydog';
      src: url(${Lazydog}) format('truetype');
      font-weight: 300;
      font-style: normal;
      font-display: auto;
  }`
);
