import { GridContainer, Text } from '../../../../../../../ui/index.ts';
import { RenderLink } from '../components/index.ts';
import { useHistoryAndCatsController } from './controller/index.ts';

export function HistoryAndCats() {
  const { historyAndCatsText, links } = useHistoryAndCatsController();

  return (
    <GridContainer gridTemplateColumns="1fr" justifyContent="center" alignItems="center">
      <GridContainer gridTemplateRows="repeat(2, max-content)" gap="24">
        <Text
          text={historyAndCatsText}
          uppercase
          family="secondary"
          size={24}
          color="red"
          weight="Bold"
          align="center"
        />
        <GridContainer
          gridTemplateColumns={`repeat(${links.length}, max-content)`}
          gap="18"
          justifyContent="center"
        >
          {links.map(RenderLink)}
        </GridContainer>
      </GridContainer>
    </GridContainer>
  );
}
