import styled from '@emotion/styled';
import { PrimaryButton, Image } from '../../../../../../../ui/index.ts';

export const ActionImage = styled(Image)`
  width: 250px;
  margin-left: 40px;
`;

export const Button = styled(PrimaryButton)`
  height: 70px;
  max-width: 250px;
  span {
    font-weight: 500;
  }

  @media only screen and (max-width: 600px) {
    max-width: 90%;
  }
`;
