import { GridContainer, Text, Icon } from '../../../../../../../ui/index.ts';
import { useStepsBlockController } from './controller/index.ts';
import { StepCardContainer, StepImage, Counter } from './styles.ts';
import { Step } from './types.ts';

function RenderStep({ id, text, image, backgroundColor, textColor, type, icon }: Step) {
  return (
    <StepCardContainer key={id} background={backgroundColor}>
      <GridContainer
        justifyContent="center"
        alignItems="center"
        gridTemaplateColumns="1fr"
        gridTemplateRows="max-content 1fr"
        gap="10"
      >
        <GridContainer justifyContent="center">
          {type === 'icon' && <Icon glyph={icon} size={100} color={textColor} />}
          {type === 'image' && <StepImage src={image} />}
        </GridContainer>
        <Text text={text} size={18} color={textColor} family="secondary" align="center" uppercase />
      </GridContainer>
      <Counter justifyContent="center" alignItems="center">
        <Text
          text={id.toString()}
          size={16}
          color={backgroundColor}
          family="secondary"
          align="center"
          uppercase
        />
      </Counter>
    </StepCardContainer>
  );
}

export function StepsBlocks() {
  const { steps } = useStepsBlockController();

  return (
    <GridContainer
      gridTemplateColumns="repeat(auto-fit, minmax(250px, 1fr))"
      gridTemplateRows="repeat(2, 1fr)"
      gap="20"
    >
      {steps.map(RenderStep)}
    </GridContainer>
  );
}
