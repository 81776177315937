import { useRef, useMemo, useEffect, useCallback, useState } from 'react';
import { useGetMessagesMutation } from '../../../../../../../packages/api/index.ts';

export function useMessagesCardModel() {
  const loadingRef = useRef(false);
  const [getMessagesMutation, { data, isLoading }] = useGetMessagesMutation();
  const [messages, setMessages] = useState([]);
  const page = 1;
  const limit = 6;

  const isEmpty = useMemo(
    () => !isLoading && Number(data?.metadata?.total) === 0,
    [isLoading, data?.metadata?.count]
  );

  const hasLoadMore = useMemo(() => {
    const meta = data?.metadata;
    if (meta) {
      return Number(meta.total) !== messages.length;
    }
    return true;
  }, [data?.metadata, messages.length]);

  const refetch = useRef(async () => {
    const { data: resData } = await getMessagesMutation({
      page,
      limit,
    });
    setMessages((prev) => [...prev, ...(resData?.data || [])]);
  });

  const loadMore = useCallback(async () => {
    const meta = data?.metadata;
    if (!loadingRef.current && meta ? messages.length !== Number(meta.total) : true) {
      loadingRef.current = true;
      const { data: resData } = await getMessagesMutation({
        page: Number(meta?.page || 0) + 1,
        limit,
      });
      setMessages((prev) => [...prev, ...(resData?.data || [])]);
      loadingRef.current = false;
    }
  }, [data?.metadata, getMessagesMutation, messages.length]);

  useEffect(() => {
    refetch.current();
  }, []);

  return {
    isEmpty,
    loadMore,
    hasLoadMore,
    isLoading,
    messages,
  };
}
