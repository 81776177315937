import { createRoot } from 'react-dom/client';
import './modules/index.css';
import init from './modules/init.tsx';
import reportWebVitals from './reportWebVitals';

const app = async () => {
  const podariBalli = document.getElementById('podari-balli');
  const root = createRoot(podariBalli);
  const virtualDom = await init();
  root.render(virtualDom);
};

reportWebVitals();
app();
