import { PropsWithChildren } from 'react';
import ReactContentLoader from 'react-content-loader';
import { getLoader } from '../../../utils/index.ts';
import { ContentLoaderViewProps } from './types.ts';

export function ContentLoader({
  children,
  backgroundColor,
  foregroundColor,
  ...props
}: PropsWithChildren<ContentLoaderViewProps>) {
  return (
    <ReactContentLoader
      {...props}
      backgroundColor={backgroundColor || getLoader('background')}
      foregroundColor={foregroundColor || getLoader('foreground')}
    >
      {children}
    </ReactContentLoader>
  );
}
