import styled from '@emotion/styled';

export const Container = styled.div`
  margin: auto;
  height: 100%;
  max-height: 100%;
  max-width: 1200px;
  box-sizing: border-box;
  position: relative;
  padding-top: 10px;
  padding-bottom: 10px;
`;
