import { getColor, getFont } from '../../utils/index.ts';
import { AVAILABLE_WEIGHT_TYPES, EmotionTextProps } from './types.ts';

export function getDefaultTextProps({
  family,
  weight,
  size = 14,
  lineHeight,
  uppercase,
  color,
  align,
}: EmotionTextProps) {
  return `
      font-family: ${getFont(family || 'primary')};
      font-style: normal;
      font-weight: ${AVAILABLE_WEIGHT_TYPES[weight || 'Regular']};
      font-size: ${size}px;
      line-height: ${lineHeight || '1.3'};
      color: ${getColor(color || 'main-deep-5')};
      ${align ? `text-align: ${align};` : ''}
      ${uppercase ? 'text-transform: uppercase;' : ''}
    `;
}
