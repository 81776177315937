import styled from '@emotion/styled';
import { GridContainer, getColor, getSpace } from '../../../../../../../../../ui/index.ts';

export const TitleContainer = styled(GridContainer)`
  background: ${getColor('pink')};
  padding: ${getSpace('18')};
  border-radius: 30px;
  max-width: 300px;
`;

export const Circle = styled.div`
  width: 50px;
  height: 50px;
  background: ${getColor('light-beige-2')};
  border-radius: 50%;
`;
