import loadable from '@loadable/component';
import { LoadableFunctionalComponent } from '../../utils/index.ts';
import { IconLoader } from '../../shared/index.ts';
import { IconViewProps } from './types.ts';
import { Icons } from './component/components/icons/types.ts';

export { Icons } from './component/components/icons/types.ts';

export const Icon = loadable(() => import('./component/index.tsx'), {
  resolveComponent: (components) => components.IconComponent,
  fallback: <IconLoader />,
}) as LoadableFunctionalComponent<IconViewProps<keyof typeof Icons>>;
