import { GridContainer } from '../../../../../ui/index.ts';
import { Container, Title } from './styles.ts';
import { Photos, ActionButton } from './components/index.ts';
import { useMainCardController } from './controller/index.ts';

export function MainCard() {
  const { mainTitleText } = useMainCardController();

  return (
    <Container gridTemplateColumns="repeat(auto-fit, minmax(250px, 1fr))" gap="20">
      <GridContainer gridTemplateRows="max-content 1fr" gap="18">
        <Title
          text={mainTitleText}
          weight="Bold"
          size={70}
          family="secondary"
          uppercase
          color="pink"
        />
        <GridContainer alignItems="end">
          <ActionButton />
        </GridContainer>
      </GridContainer>
      <Photos />
    </Container>
  );
}
