import styled from '@emotion/styled';
import { getColor, getSpace, GridContainer, Image } from '../../../../../../../ui/index.ts';

export const Container = styled(GridContainer)`
  border-radius: 30px;
  background: ${getColor('pink')};
  padding: ${getSpace('28')} ${getSpace('28')};
  overflow: hidden;
`;

export const OwnerImg = styled(Image)`
  width: 300px;
  max-height: 450px;
  transform: translate(-6.45833px, 0px) rotate(0deg);
  opacity: 1;
  border-radius: 20px;
  z-index: 1;
`;

export const ImagesContainer = styled(GridContainer)`
  position: relative;
`;

export const BrainImgContainer = styled.div`
  position: absolute;
  left: -4%;
  top: -5%;
  z-index: 2;
`;

export const BrainImg = styled(Image)`
  width: 100px;
`;
