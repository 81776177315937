import styled from '@emotion/styled';
import { PREFIX } from '../../utils/index.ts';
import { getColor } from '../../../utils/index.ts';
import { LoaderThemeProviderViewProps } from './types.ts';

export const LoaderThemeContainer = styled.div<LoaderThemeProviderViewProps>`
  ${({ background, foreground }) => `
    ${PREFIX.LOADER}background: ${getColor(background || 'main-deep-1')};
    ${PREFIX.LOADER}foreground: ${getColor(foreground || 'main-deep-2')};
  `}
`;
