import { Text, GridContainer } from '../../../../../../../../../ui/index.ts';
import { useTextsController } from './controller/index.ts';

export function Texts() {
  const { text3, text1, text2 } = useTextsController();

  return (
    <GridContainer gridTemplateRows="repeat(3, 1fr)" gap="20">
      <Text text={text1} color="black-primary" size={16} weight="Bold" />
      <Text text={text2} color="black-primary" size={16} weight="Bold" />
      <Text text={text3} color="black-primary" size={16} weight="Bold" />
    </GridContainer>
  );
}
