import { Container } from './styles.ts';
import { Title, Texts, Photos } from './components/index.ts';

export function Description() {
  return (
    <Container gridTemplateRows="max-content max-content" gap="20">
      <Title />
      <Texts />
      <Photos />
    </Container>
  );
}
