import { PropsWithChildren } from 'react';
import { ButtonByType } from '../components/index.ts';
import { ButtonViewProps } from '../types.ts';

export function PrimaryButton(props: PropsWithChildren<ButtonViewProps>) {
  const { children } = props;
  return (
    <ButtonByType {...props} color="white-primary" background="orange">
      {children}
    </ButtonByType>
  );
}
