import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { NextStep } from '../types.ts';

import stepImage1 from './images/step-image-1.gif';
import stepImage2 from './images/step-image-2.png';
import stepImage3 from './images/step-image-3.png';

export function useNextStepsController() {
  const { t } = useTranslation();

  const steps: NextStep[] = useMemo(
    () => [
      { id: '1', text: t('charity.whatsNext.step1'), image: stepImage1 },
      { id: '2', text: t('charity.whatsNext.step2'), image: stepImage2 },
      { id: '3', text: t('charity.whatsNext.step3'), image: stepImage3 },
    ],
    [t]
  );

  return { steps };
}
