import { PropsWithChildren } from 'react';
import { Content, Footer, Header } from './components/index.ts';
import { Container } from './styles.ts';

export function Layout({ children }: PropsWithChildren) {
  return (
    <Container gridTemplateRows="100%">
      <Container gridTemplateRows="20px 1fr max-content" justifyContent="center">
        <Header />
        <Content>{children}</Content>
        <Footer />
      </Container>
    </Container>
  );
}
