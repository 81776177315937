import styled from '@emotion/styled';
import { GridContainer, getColor, getSpace, Image } from '../../../../../../../ui/index.ts';

export const Container = styled(GridContainer)`
  max-width: 400px;
  padding: ${getSpace('10')} ${getSpace('10')};
  background: ${getColor('blue')};
  border-radius: 5px;
`;

export const TitleBlockImage = styled(Image)`
  width: 200px;
`;
