import { BrowserRouter, Routes, Route } from 'react-router-dom';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Provider } from 'react-redux';
import { store } from '../../packages/api/index.ts';
import { ThemeProvider } from '../ui/index.ts';
import { CharityRouter } from '../charity/index.tsx';
import { Layout, GlobalError } from './components/index.ts';
import { useAppController } from './controller/index.ts';

function App() {
  const { charity } = useAppController();

  return (
    <div>
      <ThemeProvider>
        <Provider store={store}>
          <BrowserRouter>
            <GlobalError>
              <Layout>
                <Routes>
                  <Route path={charity} element={<CharityRouter />} />
                </Routes>
              </Layout>
            </GlobalError>
          </BrowserRouter>
        </Provider>
      </ThemeProvider>
    </div>
  );
}

export default App;
