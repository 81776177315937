import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FooterLink } from '../../../types.ts';

export function usePavelDeuceController() {
  const { t } = useTranslation();

  const pavelDeuceText = t('footer.pavelDeuce');

  const links: FooterLink[] = useMemo(() => {
    return [
      { id: 1, icon: 'telegram', link: 'https://t.me/pavel_deuce' },
      { id: 2, icon: 'github', link: 'https://github.com/paveldeuce' },
    ] as FooterLink[];
  }, []);

  return { pavelDeuceText, links };
}
