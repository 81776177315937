import { Link } from 'react-router-dom';
import { FooterLink } from '../../../types.ts';
import { Icon } from '../../../../../../../../ui/index.ts';

export function RenderLink({ icon, id, link }: FooterLink) {
  return (
    <Link to={link} target="_blank" key={id}>
      <Icon glyph={icon} size={50} color="red" />
    </Link>
  );
}
