import { Text } from '../../../../../../../../../ui/index.ts';
import { TitleContainer, Circle } from './styles.ts';
import { useTitleController } from './controller/index.ts';

export function Title() {
  const { titleText } = useTitleController();

  return (
    <TitleContainer
      gap="20"
      gridTemplateColumns="repeat(2, max-content)"
      alignItems="center"
      justifyContent="center"
    >
      <Circle />
      <Text color="light-beige-2" text={titleText} size={33} family="secondary" uppercase />
    </TitleContainer>
  );
}
