// eslint-disable-next-line import/no-extraneous-dependencies
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {
  GetCharityMessagesResponse,
  GetCharityMessagesBody,
  CreateCharityMessagesResponse,
  UserInputCreateMessageData,
} from './types.ts';

const { REACT_APP_API_HOST } = process.env;

export const messagesApi = createApi({
  reducerPath: 'messagesApi',
  baseQuery: fetchBaseQuery({ baseUrl: REACT_APP_API_HOST }),
  endpoints: (builder) => ({
    getMessages: builder.mutation<GetCharityMessagesResponse, GetCharityMessagesBody>({
      query: (body) => ({
        url: '/message/list',
        method: 'POST',
        body,
      }),
    }),
    createMessage: builder.mutation<CreateCharityMessagesResponse, UserInputCreateMessageData>({
      query: (body) => ({
        url: '/message',
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const { useGetMessagesMutation, useCreateMessageMutation } = messagesApi;
