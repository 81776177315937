import { GridContainer } from '../../../ui/index.ts';
import { WhatsNextCard, StepsCard, AboutCard, MainCard, MessagesCard } from './components/index.ts';
import { useCardsController } from './controller/index.ts';

export function Cards() {
  const { messagesRef } = useCardsController();

  return (
    <GridContainer gap="28">
      <MainCard />
      <StepsCard />
      <WhatsNextCard />
      <AboutCard />
      <MessagesCard messagesRef={messagesRef} />
    </GridContainer>
  );
}
