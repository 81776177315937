import { PropsWithChildren } from 'react';
import { FormCard, GridContainer } from '../../../../../ui/index.ts';
import { useFormContainer } from './controller/index.ts';
import { FormContainerViewProps } from './types.ts';

export function FormContainer({
  isLoading,
  children,
  width = '510px',
}: PropsWithChildren<FormContainerViewProps>) {
  const { label } = useFormContainer();

  return (
    <GridContainer gridTemplateColumns={width} justifyContent="center">
      <FormCard label={label} icon="leaflet" isLoading={isLoading}>
        {children}
      </FormCard>
    </GridContainer>
  );
}
