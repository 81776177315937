import styled from '@emotion/styled';
import { FlexContainerEmotionProps } from './types.ts';

export const FlexContainer = styled.div<FlexContainerEmotionProps>`
  display: flex;
  flex-direction: ${({ flexDirection }) => flexDirection || 'row'};
  justify-content: ${({ justifyContent }) => justifyContent || 'flex-start'};
  align-items: ${({ alignItems }) => alignItems || 'flex-start'};
  ${({ gap }) => (gap ? `gap: ${gap}px;` : '')}
  ${({ flex }) => (flex ? `flex: ${flex};` : '')}
`;
