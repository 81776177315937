import { forwardRef } from 'react';
import InputMask from 'react-input-mask';
import { InputViewProps } from './types.ts';
import { TextInput as UITextInput } from './styles.ts';

export const Input = forwardRef<HTMLInputElement, InputViewProps>(
  (
    {
      value,
      mask = '',
      maskPlaceholder,
      placeholder,
      disabled,
      error,
      type,
      className,
      onChange,
      onFocus,
      onBlur,
      onKeyPress,
    },
    ref
  ) => {
    return (
      <InputMask
        mask={mask}
        maskPlaceholder={maskPlaceholder}
        value={value || ''}
        onChange={onChange}
        onBlur={onBlur}
        onFocus={onFocus}
        onKeyPress={onKeyPress}
        disabled={disabled}
      >
        <UITextInput
          ref={ref}
          error={error}
          placeholder={placeholder}
          className={className}
          type={type}
        />
      </InputMask>
    );
  }
);
