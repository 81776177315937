import useInfiniteScroll from 'react-infinite-scroll-hook';
import { useMessagesCardModel } from '../model/index.ts';

export function useMessagesCardController() {
  const { isLoading, loadMore, hasLoadMore, isEmpty, messages } = useMessagesCardModel();

  const [infinityRef] = useInfiniteScroll({
    loading: isLoading,
    hasNextPage: hasLoadMore,
    onLoadMore: loadMore,
  });

  return {
    infinityRef,
    isEmpty,
    messages,
    loadMore,
    hasLoadMore,
    isLoading,
  };
}
