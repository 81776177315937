import { RefAttributes } from 'react';
import loadable from '@loadable/component';
import { LoadableFunctionalComponent } from '../../utils/index.ts';
import { InputLoader } from '../../shared/index.ts';
import { TextAreaViewProps } from './component/types.ts';

export const TextArea = loadable(() => import('./component/index.tsx'), {
  resolveComponent: (components) => components.TextArea,
  fallback: <InputLoader />,
}) as LoadableFunctionalComponent<TextAreaViewProps & RefAttributes<HTMLTextAreaElement>>;
