import { useTranslation } from 'react-i18next';

export function useTextsController() {
  const { t } = useTranslation();

  const text1 = t('charity.about.text1');
  const text2 = t('charity.about.text2');
  const text3 = t('charity.about.text3');

  return { text1, text2, text3 };
}
