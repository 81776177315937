import { PropsWithChildren } from 'react';
import { GridContainer } from '../../../../containers/index.ts';
import { Text } from '../../../Text/index.ts';
import { Title } from './styles.ts';
import { InputDecoratorViewProps } from '../types.ts';
import { ErrorContainer, NoteContainer, ErrorRow } from '../shared.styles.ts';

export function VerticalInputDecorator({
  error,
  children,
  label,
  note,
  warning,
}: PropsWithChildren<InputDecoratorViewProps>) {
  return (
    <GridContainer gap="6">
      {label && (
        <Title text={label} size={14} weight="VeryBig" family="secondary" color="black-primary" />
      )}
      {children}
      {note && (
        <NoteContainer>
          <Text text={note} size={12} color="main-deep-4" />
        </NoteContainer>
      )}
      {warning && (
        <NoteContainer>
          <Text text={warning} size={12} color="warning-deep-4" />
        </NoteContainer>
      )}
      {error && error?.message && (
        <ErrorContainer>
          <ErrorRow text={error?.message} size={12} color="error-deep-3" family="secondary" />
        </ErrorContainer>
      )}
    </GridContainer>
  );
}
