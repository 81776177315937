import { Routes, Route } from 'react-router-dom';
import { Cards, MessageCreate } from './messages/index.ts';
import { useCharityRouterController } from './controller/index.ts';

export function CharityRouter() {
  const { cardsRoute, createRoute } = useCharityRouterController();

  return (
    <Routes>
      <Route index path={cardsRoute} element={<Cards />} />
      <Route path={createRoute} element={<MessageCreate />} />
    </Routes>
  );
}
