import { getRoute } from '../../../packages/context/index.ts';

export function useCharityRouterController() {
  const {
    charity: {
      messages: { cardsRoute, createRoute },
    },
  } = getRoute();

  return { cardsRoute: cardsRoute(), createRoute: createRoute() };
}
