import styled from '@emotion/styled';
import { Image, getSpace } from '../../../../../ui/index.ts';

export const FooterContainer = styled.footer`
  max-width: 1200px;
  padding-top: ${getSpace('32')};
  padding-bottom: ${getSpace('50')};
  padding-right: ${getSpace('10')};
  padding-left: ${getSpace('10')};
`;

export const FooterImage = styled(Image)`
  width: 180px;
  height: 180px;
  margin: auto;
`;
