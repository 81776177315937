import { GridContainer, Text, Icon } from '../../../../../ui/index.ts';
import { useStepsCardController } from './controller/index.ts';
import { TitleBlock, StepsBlocks } from './components/index.ts';
import { Container } from './styles.ts';

export function StepsCard() {
  const { stepsTitle } = useStepsCardController();

  return (
    <Container gridTemplateColumns="repeat(auto-fit, minmax(250px, 1fr))" gap="18">
      <GridContainer gridTemplateRows="max-content 1fr" gap="18">
        <GridContainer justifyContent="center">
          <GridContainer
            gridTemplateColumns="max-content 1fr"
            alignItems="center"
            justifyContent="center"
            gap="24"
          >
            <Icon glyph="fire" size={30} color="orange" />
            <Text
              text={stepsTitle}
              size={25}
              uppercase
              color="primary-text-color"
              family="secondary"
            />
          </GridContainer>
        </GridContainer>
        <GridContainer justifyContent="center">
          <TitleBlock />
        </GridContainer>
      </GridContainer>
      <StepsBlocks />
    </Container>
  );
}
