import { GridContainer, Text } from '../../../../../../../ui/index.ts';
import { RenderLink } from '../components/index.ts';
import { usePavelDeuceController } from './controller/index.ts';

export function PavelDeuce() {
  const { pavelDeuceText, links } = usePavelDeuceController();

  return (
    <GridContainer gridTemplateColumns="1fr" justifyContent="center" alignItems="center">
      <GridContainer gridTemplateRows="repeat(2, max-content)" gap="24">
        <Text
          text={pavelDeuceText}
          uppercase
          family="secondary"
          size={24}
          color="red"
          weight="Bold"
          align="center"
        />
        <GridContainer
          gridTemplateColumns={`repeat(${links.length}, max-content)`}
          gap="18"
          justifyContent="center"
        >
          {links.map(RenderLink)}
        </GridContainer>
      </GridContainer>
    </GridContainer>
  );
}
