import { BrainImg, Container, OwnerImg, BrainImgContainer, ImagesContainer } from './styles.ts';
import ownerImageSrc from './images/owner-image.jpeg';
import brainImageSrc from './images/brainstorm.gif';

export function OwnerImage() {
  return (
    <Container justifyContent="center" alignItems="center">
      <ImagesContainer>
        <BrainImgContainer>
          <BrainImg src={brainImageSrc} />
        </BrainImgContainer>
        <OwnerImg src={ownerImageSrc} />
      </ImagesContainer>
    </Container>
  );
}
