import { GridContainer, FlexContainer, Text } from '../../../../../../../../../ui/index.ts';
import { Container, DogImage, CatImage, DogImageContainer, CatImageContainer } from './styles.ts';
import catImageSrc from './images/cat-1.jpeg';
import dogImageSrc from './images/dog-1.jpeg';
import { usePhotosController } from './controller/index.ts';

export function Photos() {
  const { tagText } = usePhotosController();

  return (
    <GridContainer
      gridTemplateColumns="repeat(auto-fit, minmax(200px, 1fr))"
      justifyContent="space-between"
    >
      <Container gridTemplateColumns="repeat(auto-fit, minmax(200px, 1fr))" gap="18">
        <GridContainer justifyContent="center">
          <CatImageContainer>
            <CatImage src={catImageSrc} />
          </CatImageContainer>
        </GridContainer>
        <GridContainer justifyContent="center">
          <DogImageContainer>
            <DogImage src={dogImageSrc} />
          </DogImageContainer>
        </GridContainer>
      </Container>

      <FlexContainer alignItems="center" justifyContent="center">
        <Text
          text={tagText}
          size={22}
          family="secondary"
          uppercase
          weight="Bold"
          align="center"
          color="black-primary"
        />
      </FlexContainer>
    </GridContainer>
  );
}
