import { Component, PropsWithChildren } from 'react';
import { Text } from '../../../../../../../ui/index.ts';
import { ErrorClassViewProps, ErrorClassViewState } from './types.ts';

export class ErrorClass extends Component<
  PropsWithChildren<ErrorClassViewProps>,
  ErrorClassViewState
> {
  constructor(props: ErrorClassViewProps) {
    super(props);
    this.state = { type: undefined };
  }

  componentDidUpdate(prevProps: Readonly<PropsWithChildren<ErrorClassViewProps>>): void {
    const { pathname } = this.props;

    if (prevProps.pathname !== pathname) {
      this.setState({ type: undefined });
    }
  }

  componentDidCatch(error: Error) {
    if (error.name === 'ChunkLoadError') {
      this.setState({
        type: 'bundle',
      });
    } else {
      this.setState({
        type: 'some',
      });
    }
  }

  render() {
    const { children } = this.props;
    const { type } = this.state;

    if (type) {
      if (type === 'bundle') {
        return <Text text="Error Bundle, please reload page" />;
      }
      return <Text text="Something went wrong" />;
    }

    return children;
  }
}
