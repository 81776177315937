// eslint-disable-next-line import/no-extraneous-dependencies
import { configureStore } from '@reduxjs/toolkit';
// eslint-disable-next-line import/no-extraneous-dependencies
import { setupListeners } from '@reduxjs/toolkit/query';
import { messagesApi } from './messages/index.ts';

export const store = configureStore({
  reducer: {
    [messagesApi.reducerPath]: messagesApi.reducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(messagesApi.middleware),
});

setupListeners(store.dispatch);

export * from './messages/index.ts';
export * from './fileUpload/index.ts';
