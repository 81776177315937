import { GridContainer } from '../../../../../ui/index.ts';
import { FooterImage, FooterContainer } from './styles.ts';
import { HistoryAndCats, PavelDeuce } from './components/index.ts';
import footerImageSrc from './images/footer-image.png';

export function Footer() {
  return (
    <FooterContainer>
      <GridContainer
        gridTemplateColumns="repeat(auto-fit, minmax(250px, 1fr))"
        gap="50"
        justifyContent="center"
        alignItems="center"
      >
        <GridContainer justifyContent="center">
          <FooterImage src={footerImageSrc} />
        </GridContainer>
        <HistoryAndCats />
        <PavelDeuce />
      </GridContainer>
    </FooterContainer>
  );
}
