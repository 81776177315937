import { PropsWithChildren } from 'react';
import { LoaderThemeContainer } from './styles.ts';
import { LoaderThemeProviderViewProps } from './types.ts';

export function LoaderThemeProvider({
  children,
  ...props
}: PropsWithChildren<LoaderThemeProviderViewProps>) {
  return <LoaderThemeContainer {...props}>{children}</LoaderThemeContainer>;
}
