import styled from '@emotion/styled';
import { PrimaryButton } from '../../../../../../../ui/index.ts';

export const Button = styled(PrimaryButton)`
  height: 70px;
  width: 300px;
  span {
    font-weight: 500;
  }
`;
