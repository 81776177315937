import { css } from '@emotion/react';
import { PREFIX } from '../utils/index.ts';
import { COLORS } from './constants.ts';

export const APP_COLORS = css(
  Object.entries(COLORS).reduce(
    (acc, [key, value]) => `${acc}${PREFIX.COLOR}${key}: ${value || 'none'};`,
    ''
  )
);
