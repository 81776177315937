import { useEffect, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';

export function useCardsController() {
  const messagesRef = useRef(null);
  const [searchParams] = useSearchParams();
  const isFromCreating = searchParams.has('fromCreating');

  useEffect(() => {
    if (isFromCreating) {
      messagesRef?.current?.scrollIntoView();
    }
  }, [isFromCreating]);

  return { messagesRef };
}
