import { GridContainer } from '../../../../../ui/index.ts';
import { Description, OwnerImage } from './components/index.ts';

export function AboutCard() {
  return (
    <GridContainer gridTemplateColumns="repeat(auto-fit, minmax(250px, 1fr))" gap="20">
      <OwnerImage />
      <Description />
    </GridContainer>
  );
}
