import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Step } from '../types.ts';
import stepImage1 from './images/step-image-1.png';

export function useStepsBlockController() {
  const { t } = useTranslation();

  const steps: Step[] = useMemo(() => {
    return [
      {
        id: 1,
        type: 'image',
        text: t('charity.steps.step1'),
        textColor: 'light-beige',
        backgroundColor: 'light-purple-2',
        image: stepImage1,
        icon: null,
      },
      {
        id: 2,
        type: 'icon',
        textColor: 'light-beige',
        text: t('charity.steps.step2'),
        backgroundColor: 'mustard',
        image: null,
        icon: 'seeds',
      },
      {
        id: 3,
        type: 'icon',
        textColor: 'light-blue',
        text: t('charity.steps.step3'),
        backgroundColor: 'deep-orange',
        image: null,
        icon: 'circle',
      },
      {
        id: 4,
        type: 'icon',
        text: t('charity.steps.step4'),
        textColor: 'light-blue',
        backgroundColor: 'deep-orange',
        image: null,
        icon: 'leaflet',
      },
      {
        id: 5,
        type: 'icon',
        text: t('charity.steps.step5'),
        textColor: 'light-purple-2',
        backgroundColor: 'light-blue',
        image: null,
        icon: 'palm',
      },
      {
        id: 6,
        type: 'icon',
        text: t('charity.steps.step6'),
        textColor: 'deep-orange',
        backgroundColor: 'beige',
        image: null,
        icon: 'arch',
      },
    ] as Step[];
  }, [t]);

  return { steps };
}
