import { PropsWithChildren } from 'react';
import { useContentController } from './controller/index.ts';
import { Error } from './components/index.ts';
import { Container } from './styles.ts';

export function Content({ children }: PropsWithChildren) {
  const { ref } = useContentController();

  return (
    <Container ref={ref}>
      <Error>{children}</Error>
    </Container>
  );
}
