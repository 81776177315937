import { ContentLoader } from '../ContentLoader/index.tsx';
import { DefaultButtonLoaderViewProps } from './types.ts';

export function DefaultButtonLoader({ width, height }: DefaultButtonLoaderViewProps) {
  return (
    <ContentLoader width={width ? `${width}px` : '100%'} height={`${height || 32.1}px`}>
      <rect width={width ? `${width}px` : '100%'} height={`${height || 32.1}px`} rx="4" />
    </ContentLoader>
  );
}
