import styled from '@emotion/styled';
import { Image } from '../../../../../../../ui/index.ts';

export const MainImage1 = styled(Image)`
  width: 200px;
`;

export const MainImage2 = styled(Image)`
  width: 200px;
`;
