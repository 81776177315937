import { Text, GridContainer } from '../../../../../../../ui/index.ts';
import { Container, TitleBlockImage } from './styles.ts';
import { useTitleBlockController } from './controller/index.ts';
import titleBlockImage from './images/title-block-image.png';

export function TitleBlock() {
  const { titleBlockText } = useTitleBlockController();

  return (
    <Container justifyContent="center" alignItems="center" gridTemplateColumns="1fr" gap="10">
      <GridContainer justifyContent="center">
        <TitleBlockImage src={titleBlockImage} />
      </GridContainer>
      <Text
        text={titleBlockText}
        size={18}
        uppercase
        align="center"
        color="primary-text-color"
        family="secondary"
      />
    </Container>
  );
}
