import styled from '@emotion/styled';
import { Card, Image, getColor, FlexContainer } from '../../../../../../../ui/index.ts';

export const StepCardContainer = styled(Card)`
  position: relative;
  //max-width: 250px;
  height: 190px;
  overflow: hidden;
`;

export const StepImage = styled(Image)`
  width: 100px;
`;

export const Counter = styled(FlexContainer)`
  position: absolute;
  right: 10px;
  top: 10px;
  background: ${getColor('white-primary')};
  width: 30px;
  height: 30px;
  border-radius: 50%;
`;
