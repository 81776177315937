import { GridContainer, Icon } from '../../../../../../../ui/index.ts';
import { FallbackViewProps } from './types.ts';

export function Fallback({ infinityRef, hasLoadMore }: FallbackViewProps) {
  if (!hasLoadMore) {
    return null;
  }

  return (
    <GridContainer
      ref={infinityRef}
      gridTemplateRows="1fr"
      justifyContent="center"
      alignItems="center"
    >
      <Icon glyph="spinner" />
    </GridContainer>
  );
}
