import { GridContainer } from '../../../../../../../ui/index.ts';
import { Button, ActionImage } from './styles.ts';
import { useActionButtonController } from './controller/index.ts';
import actionImage from './images/action-image.jpg';

export function ActionButton() {
  const { onButtonClick, buttonText } = useActionButtonController();

  return (
    <GridContainer>
      <GridContainer justifyContent="start">
        <ActionImage src={actionImage} />
      </GridContainer>
      <Button text={buttonText} onClick={onButtonClick} />
    </GridContainer>
  );
}
