import { PropsWithChildren } from 'react';
import { LoaderThemeProvider } from '../../shared/index.ts';
import { Container } from './styles.ts';
import { CardViewProps } from './types.ts';

export function Card({
  border,
  background,
  padding,
  className,
  loaderBackground,
  loaderForeground,
  children,
}: PropsWithChildren<CardViewProps>) {
  return (
    <LoaderThemeProvider background={loaderBackground} foreground={loaderForeground}>
      <Container border={border} background={background} padding={padding} className={className}>
        {children}
      </Container>
    </LoaderThemeProvider>
  );
}
