import loadable from '@loadable/component';
import { LoadableFunctionalComponent } from '../../../ui/index.ts';
import { Fallback, FormContainer } from './components/index.ts';

export const MessageCreate = loadable(() => import('./component/index.tsx'), {
  resolveComponent: (components) => components.Create,
  fallback: (
    <FormContainer isLoading>
      <Fallback />
    </FormContainer>
  ),
}) as LoadableFunctionalComponent<NonNullable<unknown>>;
