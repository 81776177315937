import styled from '@emotion/styled';
import { getSpace, Text, GridContainer } from '../../../../../ui/index.ts';

export const Container = styled(GridContainer)`
  padding: ${getSpace('18')} ${getSpace('20')};
`;

export const Title = styled(Text)`
  @media only screen and (max-width: 600px) {
    font-size: 35px;
  }
`;
