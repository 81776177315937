import { ContentLoader } from '../ContentLoader/index.tsx';
import { ActionButtonLoaderViewProps } from './types.ts';

export function ActionButtonLoader({ width, height }: ActionButtonLoaderViewProps) {
  return (
    <ContentLoader width={`${width || 110}px`} height={`${height || 32}px`}>
      <rect width={`${width || 110}px`} height={`${height || 32}px`} rx="4" />
    </ContentLoader>
  );
}
