import { ContentLoader } from '../ContentLoader/index.tsx';
import { SmallButtonLoaderViewProps } from './types.ts';

export function SmallButtonLoader({ width, height }: SmallButtonLoaderViewProps) {
  return (
    <ContentLoader width={width ? `${width}px` : '100%'} height={`${height || 32.2}px`}>
      <rect
        width={width ? `${width}px` : '100%'}
        height={`${height || 32.2}px`}
        rx={height ? height / 2 : '16.1'}
      />
    </ContentLoader>
  );
}
